import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  const encoder = new TextEncoder();
  const csvBuffer = encoder.encode('これはサンプルCSV').buffer;

  return HttpResponse.arrayBuffer(csvBuffer, {
    headers: {
      'Content-Type': 'text/csv',
      'Content-Disposition': 'attachment; filename="mock_answerers_unfinished.csv"',
    },
  });
};

export const surveyAnswerersUnfinishedFileDownload = {
  get,
};
