import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { SURVEY } from '~operation/mocks/constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === SURVEY.SETTING_INCOMPLETE) {
    return HttpResponse.json({
      total: 0,
      fully_calculable: 0,
      partial_calculable: 0,
    });
  }

  if (params.surveyId === SURVEY.SETTING_COMPLETE) {
    return HttpResponse.json({
      total: 10,
      fully_calculable: 0,
      partial_calculable: 0,
    });
  }

  if (params.surveyId === SURVEY.CAN_NOT_CALCULATE) {
    return HttpResponse.json({
      total: 10,
      fully_calculable: 0,
      partial_calculable: 0,
    });
  }

  if (params.surveyId === SURVEY.CAN_PARTIALLY_CALCULATE) {
    return HttpResponse.json({
      total: 9999,
      fully_calculable: 0,
      partial_calculable: 2,
    });
  }

  return HttpResponse.json({
    total: 9999,
    fully_calculable: 2434,
    partial_calculable: 1000,
  });
};

export const surveyTargetUsersStatus = {
  get,
};
